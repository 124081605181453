
import BoundingBox from '@/services/api/models/BoundingBox';
import { MilkMetrics, Product } from '@/store/models/Product';
import ProductStore from '@/store/modules/ProductStore';
import User from '@/store/modules/User';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class EditCustomer extends Vue {
  public numberTanks = 1;
  public search = '';
  public form = {
    customer: {
      name: '',
      firstName: '',
      lastName: ''
    },
    address: {
      streetAddress: '',
      suburb: '',
      townCity: '',
      postCode: ''
    },
    tags: {
      tag: ''
    },
    status: 'Pending'
  };
  public boundingBox: BoundingBox = {
    northEast: { lat: -32.41120443364559, lon: 180 },
    southWest: { lat: -48.93237346190712, lon: 140.93683593750006 }
  };
  public products: Product[] = [];
  public vats: Product[] = [];
  public selectSupplierNumber = '';
  private selectedProductId: number | null = null;
  public actualSelectedId: number | null = null;

  onSubmit(event: any) {
    event.preventDefault();
    // alert(JSON.stringify(this.form));
    this.$bvToast.toast(`Edit customer feature not implemented`, {
      title: 'Not Implemented',
      toaster: 'b-toaster-bottom-center',
      solid: true,
      append: false
    });
  }

  mounted() {
    this.fetchEntities(this.boundingBox);
  }

  private fetchEntities(boundingBox?: BoundingBox): void {
    ProductStore.fetchEntityStates({
      partnerId: User._token?.orgs[User._orgIndex].orgId ?? 0,
      productRequest: {
        boundingBox,
        productType: 'milk' // This shold take productType as a param to generalise to one view.
      }
    }).then(async products => {
      this.vats = products;
      const groupedByProductID = this.groupBy(
        products,
        (value: any) => value.productId
      );
      this.products = groupedByProductID;
      this.filterProducts();
      const filteredProducts = groupedByProductID.filter(
        product => product.supplierNumber == this.$route.params.supplierNumber
      );
      this.selectedProductId = filteredProducts[0].productId;
      this.actualSelectedId = filteredProducts[0].productId;
      this.updateForm();
    });
  }

  public filterProducts(): void {
    this.selectSupplierNumber = this.$route.params.supplierNumber;
    // filter on search term
    let filtered = ProductStore.currentProducts;
    if (filtered) {
      filtered = filtered.filter(
        product => product.supplierNumber == this.selectSupplierNumber
      );

      if (filtered) {
        this.products = filtered.map((product: Product) => {
          return {
            ...product,
            metrics: product.metrics as MilkMetrics
          } as Product;
        });
      }
      this.vats = filtered;
      this.products = this.groupBy(filtered, (value: any) => value.productId);
    }
  }

  public groupBy(list: any, keyGetter: any) {
    const map = new Map();
    list.forEach((item: any) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, {
          ...item,
          entities: [
            {
              name: item.name,
              entityId: item.entityId,
              capacity: item.capacity,
              metrics: {
                ...item.metrics,
                capacity: item.capacity
              }
            }
          ]
        });
      } else {
        const index = collection.entities.length;
        collection.entities.push({
          name: item.name,
          entityId: item.entityId,
          capacity: item.capacity,
          metrics: {
            ...item.metrics,
            capacity: item.capacity
          }
        });
      }
    });
    return Array.from(map).map(product => product[1]);
  }

  public updateForm() {
    this.form.customer.name = this.products[0]
      ? this.products[0].siteName ?? ''
      : '';
    const address = this.products[0]
      ? this.products[0].address?.split(',')
      : [];

    if (address?.length != 0 && address) {
      this.form.address.streetAddress = address[0] ?? '';
      if (address?.length == 2) {
        this.form.address.townCity = address[1].slice(0, -4) ?? '';
        this.form.address.postCode =
          address[1].substring(address[1].length - 4) ?? '';
      } else {
        this.form.address.suburb = address[1] ?? '';
        this.form.address.townCity = address[2].slice(0, -4) ?? '';
        this.form.address.postCode =
          address[2].substring(address[2].length - 4) ?? '';
      }
    }
  }

  public valueUpdated(searchTerm: string) {
    this.search = searchTerm;
  }
}
