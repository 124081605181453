
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class EditCustomer extends Vue {
  public numberTanks = 1;
  public search = '';
  public form = {
    customer: {
      name: '',
      firstName: '',
      lastName: ''
    },
    address: {
      streetAddress: '',
      suburb: '',
      townCity: '',
      postCode: ''
    },
    tags: {
      tag: ''
    },
    status: 'Pending'
  };
  onSubmit(event: any) {
    event.preventDefault();
    alert(JSON.stringify(this.form));
  }

  public valueUpdated(searchTerm: string) {
    this.search = searchTerm;
  }
}
